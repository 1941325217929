<template>
  <div v-if="clientSiteData && $can('update', 'clients')">

    <!-- Media -->
    <b-media class="mb-2">
      <h2 class="mb-1">
        {{ clientSiteData.sitename }}
      </h2>
    </b-media>

    <b-modal
      id="validation-error-modal"
      ref="validationErrorModal"
      title="Form Validation Error"
      ok-only
      ok-title="Close"
      ok-variant="primary"
    >
      <div class="d-flex align-items-center">
        <feather-icon
          icon="AlertTriangleIcon"
          class="mr-2"
          size="24"
          style="color: #EA5455;"
        />
        <span>Please fill in all required fields before submitting the form.</span>
      </div>
      <template #modal-footer="{ ok }">
        <b-button
          variant="primary"
          @click="ok()"
        >
          Close
        </b-button>
      </template>
    </b-modal>

    <!-- Client Info: Input Fields -->
    <validation-observer
      ref="observer"
      #default=""
    >
      <b-form
        @submit.prevent="onSubmit"
      >
        <b-tabs @input="onTabChanged">
          <!-- ***** ***** General Information ***** ***** -->
          <b-tab>
            <template #title>
              <feather-icon icon="HomeIcon" />
              <span>General Site Information</span>
            </template>
            <b-row>
              <!-- Site Name -->
              <b-col
                md="6"
              >
                <b-form-group
                  label="Site Name"
                  label-for="sitename"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="sitename"
                    rules="required"
                  >
                    <b-form-input
                      id="sitename"
                      v-model="clientSiteData.sitename"
                      :readonly="true"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Field: Clientname -->
              <b-col
                md="6"
              >
                <b-form-group
                  label="Client Name"
                  label-for="client_display_name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="client_display_name"
                    rules="required"
                  >
                    <b-form-input
                      id="client_display_name"
                      v-model="clientSiteData.client_display_name"
                      :state="errors.length > 0 ? false:null"
                      :readonly="true"
                      name="client_display_name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Address Info -->
            <b-row>
              <!-- Address -->
              <b-col
                md="6"
              >
                <b-form-group
                  label="Address"
                  label-for="address"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="address"
                    rules="required"
                  >
                    <b-form-input
                      id="address"
                      v-model="clientSiteData.address"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Unit -->
              <b-col
                md="6"
              >
                <b-form-group
                  label="Unit"
                  label-for="addressUnit"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="addressUnit"
                  >
                    <b-form-input
                      id="addressUnit"
                      v-model="clientSiteData.addressUnit"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- City -->
              <b-col
                md="6"
              >
                <b-form-group
                  label="City"
                  label-for="city"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="city"
                    rules="required"
                  >
                    <b-form-input
                      id="city"
                      v-model="clientSiteData.city"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Country -->
              <b-col
                md="6"
              >
                <b-form-group
                  label="Country"
                  label-for="country"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="country"
                    rules="required"
                  >
                    <v-select
                      v-model="clientSiteData.country"
                      :options="countryOptions"
                      :reduce="val => val.value"
                      input-id="country"
                      :clearable="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Province -->
              <b-col
                md="6"
              >
                <b-form-group
                  label="State / Province"
                  label-for="province"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="province"
                    rules="required"
                  >
                    <v-select
                      v-model="clientSiteData.province"
                      :options="provinceOptions"
                      :reduce="val => val.value"
                      input-id="province"
                      :clearable="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Postal -->
              <b-col
                md="6"
              >
                <b-form-group
                  label="Postal Code"
                  label-for="postal"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="postal"
                    rules="required"
                  >
                    <b-form-input
                      id="postal"
                      v-model="clientSiteData.postal"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Phone -->
              <b-col
                md="6"
              >
                <b-form-group
                  label="Phone"
                  label-for="phone"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="phone"
                    rules="required"
                  >
                    <b-input-group>
                      <cleave
                        id="phone"
                        v-model="clientSiteData.phone"
                        class="form-control"
                        :raw="false"
                        :options="options.phone"
                        placeholder="123 123 1234"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" />
              <!-- Location Type -->
              <b-col
                md="6"
              >
                <b-form-group
                  label="Location Type"
                  label-for="is_main_location"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="is_main_location"
                  >
                    <b-form-checkbox
                      v-model="clientSiteData.is_main_location"
                      class="mb-1"
                      name="check-button"
                      switch
                      inline
                    >
                      <h5>
                        Main Office (Used for Billing)
                      </h5>
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Status -->
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Status"
                  label-for="is_active"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="is_active"
                    rules="required"
                  >
                    <v-select
                      v-model="clientSiteData.is_active"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="is_active"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              </b-col>
            </b-row>
            <!-- Services list -->
            <b-row>
              <b-col>
                <b-form-group
                  label="Services"
                  label-for="services"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="services"
                  >
                    <b-form-checkbox
                      v-model="clientSiteData.services.guard"
                      class="mb-1"
                      name="check-button"
                      switch
                      inline
                    >
                      <h5>
                        Guard Services
                      </h5>
                    </b-form-checkbox>
                    <br>
                    <b-form-checkbox
                      v-model="clientSiteData.services.videomonitoring"
                      class="mb-1"
                      name="check-button"
                      switch
                      inline
                    >
                      <h5>
                        Video Monitoring Services
                      </h5>
                    </b-form-checkbox>
                    <br>
                    <b-form-checkbox
                      v-model="clientSiteData.services.videoretention"
                      class="mb-1"
                      name="check-button"
                      switch
                      inline
                    >
                      <h5>
                        Video Retention Services
                      </h5>
                    </b-form-checkbox>
                    <br>
                    <b-form-checkbox
                      v-model="clientSiteData.services.guardtour"
                      class="mb-1"
                      name="check-button"
                      switch
                      inline
                    >
                      <h5>
                        Guard Tour Services
                      </h5>
                    </b-form-checkbox>
                    <br>
                    <b-form-checkbox
                      v-model="clientSiteData.services.tenant"
                      class="mb-1"
                      name="check-button"
                      switch
                      inline
                    >
                      <h5>
                        Tenant Services
                      </h5>
                    </b-form-checkbox>
                    <br>
                    <b-form-checkbox
                      v-model="clientSiteData.services.privateinvestigation"
                      class="mb-1"
                      name="check-button"
                      switch
                      inline
                    >
                      <h5>
                        Private Investigation Services
                      </h5>
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <!-- ***** ***** Security Services Settings ***** ***** -->
          <b-tab v-if="clientSiteData.services.guard === true || clientSiteData.services.guardtour === true || clientSiteData.services.tenant === true">
            <template #title>
              <feather-icon icon="HomeIcon" />
              <span>Security Services Settings</span>
            </template>
            <b-row>
              <!-- client type -->
              <b-col
                md="6"
              >
                <b-form-group
                  label="Client Type"
                  label-for="clienttype"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="clienttype"
                  >
                    <v-select
                      v-model="clientSiteData.client_type"
                      :options="clienttypeOptions"
                      :reduce="val => val.value"
                      input-id="clienttype"
                      :clearable="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Region Location -->
              <b-col
                md="6"
              >
                <b-form-group
                  label="Region"
                  label-for="regionloc"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="regionloc"
                  >
                    <v-select
                      v-model="clientSiteData.region_loc"
                      :options="regionLocOptions"
                      :reduce="val => val.value"
                      input-id="regionloc"
                      :clearable="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Billing Rate -->
              <b-col
                md="6"
              >
                <b-form-group
                  label="Billing Rate"
                  label-for="billrate"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="billrate"
                  >
                    <b-form-input
                      id="billrate"
                      v-model="clientSiteData.bill_rate"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Special Billing Rate -->
              <b-col
                md="6"
              >
                <b-form-group
                  label="Special Billing Rate"
                  label-for="specialbillrate"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="specialbillrate"
                  >
                    <b-form-input
                      id="specialbillrate"
                      v-model="clientSiteData.special_bill_rate"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Location Settings -->
            <!-- Title -->
            <b-row class="mt-2 mb-1">
              <b-col>
                <h5>Location Mapping Information</h5>
              </b-col>
            </b-row>
            <!-- Location Fields -->
            <b-row>
              <!-- Latitude -->
              <b-col
                md="6"
              >
                <b-form-group
                  label="Latitude"
                  label-for="latitude"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="latitude"
                    rules="regex:^([+-]?([0-9]+\.[0-9]+))$"
                  >
                    <b-form-input
                      id="latitude"
                      v-model="clientSiteData.geofence.lat"
                      @change="updateMap"
                      @keyup="updateMap"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Longitude -->
              <b-col
                md="6"
              >
                <b-form-group
                  label="Longitude"
                  label-for="longitude"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="longitude"
                    rules="regex:^([+-]?([0-9]+\.[0-9]+))$"
                  >
                    <b-form-input
                      id="longitude"
                      v-model="clientSiteData.geofence.lon"
                      @change="updateMap"
                      @keyup="updateMap"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Distance -->
              <b-col
                md="6"
              >
                <b-form-group
                  label="Distance (metres)"
                  label-for="distance"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="distance"
                    rules="integer"
                  >
                    <b-form-input
                      id="distance"
                      v-model="clientSiteData.geofence.distance"
                      @change="updateMap"
                      @keyup="updateMap"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Map -->
            <b-row>
              <!-- Button -->
              <b-col
                v-if="clientSiteData.address !== '' && clientSiteData.city !== '' && clientSiteData.province !== '' && clientSiteData.country !== ''"
                md="3"
                class="mt-2"
              >
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  block
                  @click="lookupAddress"
                >
                  Lookup Address
                </b-button>
              </b-col>
            </b-row>
            <!-- Map -->
            <b-row
              v-if=" typeof clientSiteData.geofence.lat !== 'undefined' &&
                typeof clientSiteData.geofence.lon !== 'undefined' &&
                typeof clientSiteData.geofence.distance !== 'undefined' &&
                clientSiteData.geofence.lat !== '' &&
                clientSiteData.geofence.lon !== '' &&
                clientSiteData.geofence.distance !== ''
              "
              ref="theMap"
              :key="mapKey"
              class="mt-2"
            >
              <b-col>
                <div>
                  <l-map
                    ref="myMap"
                    :zoom="zoom"
                    :center="center"
                  >
                    <l-tile-layer :url="url" />
                    <l-marker :lat-lng="markerLatLng" />
                    <l-circle
                      :lat-lng="circle.center"
                      :radius="circle.radius"
                      :color="circle.color"
                    />
                    <!-- <l-polygon
                      :lat-lngs="polygon.latlngs"
                      :color="polygon.color"
                    /> -->
                  </l-map>
                </div>
              </b-col>
            </b-row>
          </b-tab>
          <!-- ***** ***** Tenant Settings ***** ***** -->
          <b-tab v-if="clientSiteData.services.tenant === true">
            <template #title>
              <feather-icon icon="HomeIcon" />
              <span>Tenant Settings</span>
            </template>
            <!-- Fields -->
            <b-row>
              <!-- Tenant URL -->
              <b-col
                md="6"
              >
                <b-form-group
                  label="Tenant URL"
                  label-for="tenanturl"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="tenanturl"
                  >
                    <b-form-input
                      id="tenanturl"
                      v-model="clientSiteData.tenant.url"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Tenant Permits -->
              <b-col
                md="6"
              >
                <b-form-group
                  label="Tenant Password"
                  label-for="tenantpassword"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="tenantpassword"
                  >
                    <b-form-input
                      id="tenantpassword"
                      v-model="clientSiteData.tenant.password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Tenant Max Permits -->
              <b-col
                md="6"
              >
                <b-form-group
                  label="Tenant Max Permits"
                  label-for="tenantMaxPermits"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="tenantMaxPermits"
                    rules="integer"
                  >
                    <b-form-input
                      id="tenantMaxPermits"
                      v-model="clientSiteData.tenant.max_permits"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <!-- ***** ***** Video Monitoring Services Settings ***** ***** -->
          <b-tab v-if="clientSiteData.services.videomonitoring === true">
            <template #title>
              <feather-icon icon="HomeIcon" />
              <span>Video Monitoring Services Settings</span>
            </template>
            <b-row class="mt-2 mb-1">
              <b-col
                md="6"
              >
                <h4>Services</h4>
              </b-col>
            </b-row>
            <!-- Services list -->
            <b-row>
              <b-col>
                <b-form-group
                  label=""
                  label-for="services"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="services"
                  >
                    <b-form-checkbox
                      v-model="clientSiteData.videomonitoringservices.remotevideomonitoring"
                      class="mb-1"
                      name="check-button"
                      switch
                      inline
                    >
                      <h5>
                        Remote Video Monitoring
                      </h5>
                    </b-form-checkbox>
                    <br>
                    <b-form-checkbox
                      v-model="clientSiteData.videomonitoringservices.mobileappenabled"
                      class="mb-1"
                      name="check-button"
                      switch
                      inline
                    >
                      <h5>
                        Mobile App Enabled
                      </h5>
                    </b-form-checkbox>
                    <br>
                    <b-form-checkbox
                      v-model="clientSiteData.videomonitoringservices.voicetalkdown"
                      class="mb-1"
                      name="check-button"
                      switch
                      inline
                    >
                      <h5>
                        Voice Talk-Down
                      </h5>
                    </b-form-checkbox>
                    <br>
                    <b-form-checkbox
                      v-model="clientSiteData.videomonitoringservices.videoverification"
                      class="mb-1"
                      name="check-button"
                      switch
                      inline
                    >
                      <h5>
                        Video Verification
                      </h5>
                    </b-form-checkbox>
                    <br>
                    <b-form-checkbox
                      v-model="clientSiteData.videomonitoringservices.virtualguardtours"
                      class="mb-1"
                      name="check-button"
                      switch
                      inline
                    >
                      <h5>
                        Virtual Guard Tours
                      </h5>
                    </b-form-checkbox>
                    <br>
                    <b-form-checkbox
                      v-model="clientSiteData.videomonitoringservices.elevatormonitoring"
                      class="mb-1"
                      name="check-button"
                      switch
                      inline
                    >
                      <h5>
                        Elevator Monitoring
                      </h5>
                    </b-form-checkbox>
                    <br>
                    <b-form-checkbox
                      v-model="clientSiteData.videomonitoringservices.cloudanalytics"
                      class="mb-1"
                      name="check-button"
                      switch
                      inline
                    >
                      <h5>
                        Cloud Analytics
                      </h5>
                    </b-form-checkbox>
                    <br>
                    <b-form-checkbox
                      v-model="clientSiteData.videomonitoringservices.remoteconcierge"
                      class="mb-1"
                      name="check-button"
                      switch
                      inline
                    >
                      <h5>
                        Remote Concierge
                      </h5>
                    </b-form-checkbox>
                    <br>
                    <b-form-checkbox
                      v-model="clientSiteData.videomonitoringservices.sensoreventtrigger"
                      class="mb-1"
                      name="check-button"
                      switch
                      inline
                    >
                      <h5>
                        Sensor/Event Trigger
                      </h5>
                    </b-form-checkbox>
                    <br>
                    <b-form-checkbox
                      v-model="clientSiteData.videomonitoringservices.initialsiteprogramming"
                      class="mb-1"
                      name="check-button"
                      switch
                      inline
                    >
                      <h5>
                        Initial Site Programming
                      </h5>
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Artificial Intelligence -->
            <b-row class="mt-2 mb-1 pt-1 border-top-secondary">
              <b-col
                md="12"
              >
                <h4>Artificial Intelligence</h4>
                (WARNING: All sites require advanced analytics)
              </b-col>
            </b-row>
            <b-row>
              <!-- Do you require AI Analytics? -->
              <b-col
                md="6"
              >
                <b-form-group
                  label="Do you require AI Analytics?"
                  label-for="vmsaianalytics"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vmsaianalytics"
                  >
                    <v-select
                      v-model="clientSiteData.videomonitoringservices.aianalytics"
                      :options="vmsaianalyticsOptions"
                      :reduce="val => val.value"
                      input-id="vmsaianalytics"
                      :clearable="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Type of AI used for Cameras? (Manufacturer or type) -->
              <b-col
                md="6"
              >
                <b-form-group
                  label="Type of AI used for Cameras? (Manufacturer or type)"
                  label-for="vmsaitype"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vmsaitype"
                  >
                    <b-form-input
                      id="vmsaitype"
                      v-model="clientSiteData.videomonitoringservices.vmsaitype"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Event Contact List -->
            <b-row class="mt-2 mb-1 pt-1 border-top-secondary">
              <b-col
                md="12"
              >
                <h4>Event Contact List</h4>
              </b-col>
            </b-row>
            <!-- New Event Contact Person Form -->
            <!-- New Event Contact Person Name Field -->
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Name"
                  label-for="vmsnewEventPersonName"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vmsnewEventPersonName"
                  >
                    <b-form-input
                      id="vmsnewEventPersonName"
                      v-model="vmsNewEventPersonName"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- New Event Contact Person Phone Field -->
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Phone"
                  label-for="vmsnewEventPersonPhone"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vmsnewEventPersonPhone"
                  >
                    <!-- <b-form-input
                      id="vmsnewEventPersonPhone"
                      v-model="vmsNewEventPersonPhone"
                    /> -->
                    <b-input-group>
                      <cleave
                        id="vmsnewEventPersonPhone"
                        v-model="vmsNewEventPersonPhone"
                        class="form-control"
                        :raw="false"
                        :options="options.phone"
                        placeholder="123 123 1234"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- New Event Contact Person Email Field -->
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Email"
                  label-for="vmsnewEventPersonEmail"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vmsnewEventPersonEmail"
                    rules="email"
                  >
                    <b-form-input
                      id="vmsnewEventPersonEmail"
                      v-model="vmsNewEventPersonEmail"
                      type="email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- New Event Contact Person Buttons Field -->
            <b-row class="mb-1">
              <b-col
                v-if="$can('update', 'clients')"
                md="2"
              >
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  block
                  type="button"
                  @click="handleAddEventPerson()"
                >
                  {{ vmsAddEventPersonBtnLable }}
                </b-button>
              </b-col>
              <b-col
                v-if="$can('update', 'clients')"
                md="2"
              >
                <b-button
                  variant="secondary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  block
                  type="button"
                  @click="resetVMSEventTypeForm()"
                >
                  Cancel
                </b-button>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col
                v-if="$can('update', 'clients')"
                md="12"
              >
                <span>Items in the table below will be save when you click on the Save Changes button at the bottom of the form.</span>
              </b-col>
            </b-row>
            <!-- table -->
            <b-table
              ref="eventPersonTable"
              class="position-relative"
              :items="clientSiteData.videomonitoringservices.eventPersons"
              responsive
              :fields="vmsEventPersonstableColumns"
              primary-key="itemID"
              show-empty
              empty-text=""
              style="min-height: 10rem;"
            >
              <template empty="">
                No Site found for this client
              </template>

              <!-- Column: itemID -->
              <template #cell(itemID)="data">
                <div class="text-nowrap">
                  {{ data.item.itemID }}
                </div>
              </template>
              <!-- Column: vmsNewEventPersonName -->
              <template #cell(eventPersonName)="data">
                <div class="text-nowrap">
                  {{ data.item.eventPersonName }}
                </div>
              </template>
              <!-- Column: vmsNewEventPersonPhone -->
              <template #cell(eventPersonPhone)="data">
                <div class="text-nowrap">
                  {{ formatPhoneNumber(data.item.eventPersonPhone) }}
                </div>
              </template>
              <!-- Column: eventPersonEmail -->
              <template #cell(eventPersonEmail)="data">
                <div class="text-nowrap">
                  {{ data.item.eventPersonEmail }}
                </div>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-dropdown
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >

                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item
                    v-if="$can('update', 'clients')"
                    @click="handleEditEventPersonFillForm(data.item)"
                  >
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Edit</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-if="$can('delete', 'clients')"
                    @click="handleDeleteEventPerson(data.item)"
                  >
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
            <!-- Pagination -->
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              />
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-right justify-content-right justify-content-sm-end"
              >
                <b-pagination
                  v-model="vmsEventPersonsCurrentPage"
                  :total-rows="vmstotalEventPersons"
                  :per-page="vmsperPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
            <!-- Police -->
            <b-row>
              <b-col
                md="6"
              >
                <h5>Police Telephone Number</h5>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="3"
              >
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="vmspolicetelnum"
                  >
                    <!-- <b-form-input
                      id="vmspolicetelnum"
                      v-model="clientSiteData.videomonitoringservices.vmspolicetelnum"
                    /> -->
                    <b-input-group>
                      <cleave
                        id="vmspolicetelnum"
                        v-model="clientSiteData.videomonitoringservices.vmspolicetelnum"
                        class="form-control"
                        :raw="false"
                        :options="options.phone"
                        placeholder="123 123 1234"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Fire -->
            <b-row>
              <b-col
                md="6"
              >
                <h5>Fire Telephone Number</h5>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="3"
              >
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="vmsfiretelnum"
                  >
                    <!-- <b-form-input
                      id="vmsfiretelnum"
                      v-model="clientSiteData.videomonitoringservices.vmsfiretelnum"
                    /> -->
                    <b-input-group>
                      <cleave
                        id="vmsfiretelnum"
                        v-model="clientSiteData.videomonitoringservices.vmsfiretelnum"
                        class="form-control"
                        :raw="false"
                        :options="options.phone"
                        placeholder="123 123 1234"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- ***** Monitoring Schedule ***** -->
            <b-row class="mt-2 mb-1 pt-1 border-top-secondary">
              <b-col
                md="12"
              >
                <h4>Monitoring Schedule</h4>
                (Please discrible the schedule for each day of monitoring. e.g. Monday - 6pm to 6am, Saturady - 24hrs)
              </b-col>
            </b-row>
            <!-- Monday -->
            <b-row>
              <b-col
                md="3"
              >
                <b-form-group
                  label="Monday"
                  label-for="vmsschedulemon"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vmsschedulemon"
                  >
                    <b-form-input
                      id="vmsschedulemon"
                      v-model="clientSiteData.videomonitoringservices.schedule.monday"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Tuesday -->
            <b-row>
              <b-col
                md="3"
              >
                <b-form-group
                  label="Tuesday"
                  label-for="vmsscheduletues"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vmsscheduletues"
                  >
                    <b-form-input
                      id="vmsscheduletues"
                      v-model="clientSiteData.videomonitoringservices.schedule.tuesday"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Wednesday -->
            <b-row>
              <b-col
                md="3"
              >
                <b-form-group
                  label="Wednesday"
                  label-for="vmsschedulewed"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vmsschedulewed"
                  >
                    <b-form-input
                      id="vmsschedulewed"
                      v-model="clientSiteData.videomonitoringservices.schedule.wednesday"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Thursday -->
            <b-row>
              <b-col
                md="3"
              >
                <b-form-group
                  label="Thursday"
                  label-for="vmsschedulethurs"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vmsschedulethurs"
                  >
                    <b-form-input
                      id="vmsschedulethurs"
                      v-model="clientSiteData.videomonitoringservices.schedule.thursday"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Friday -->
            <b-row>
              <b-col
                md="3"
              >
                <b-form-group
                  label="Friday"
                  label-for="vmsschedulefri"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vmsschedulefri"
                  >
                    <b-form-input
                      id="vmsschedulefri"
                      v-model="clientSiteData.videomonitoringservices.schedule.friday"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Saturday -->
            <b-row>
              <b-col
                md="3"
              >
                <b-form-group
                  label="Saturday"
                  label-for="vmsschedulesat"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vmsschedulesat"
                  >
                    <b-form-input
                      id="vmsschedulesat"
                      v-model="clientSiteData.videomonitoringservices.schedule.saturday"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Sunday -->
            <b-row>
              <b-col
                md="3"
              >
                <b-form-group
                  label="Sunday"
                  label-for="vmsschedulesun"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vmsschedulesun"
                  >
                    <b-form-input
                      id="vmsschedulesun"
                      v-model="clientSiteData.videomonitoringservices.schedule.sunday"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Time Zone -->
            <b-row>
              <b-col
                md="6"
              >
                <h5>Time Zone</h5>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="3"
              >
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="vmstimezone"
                  >
                    <b-form-input
                      id="vmsfiretelnum"
                      v-model="clientSiteData.videomonitoringservices.vmstimezone"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- ***** Events Requiring Escalation ***** -->
            <b-row class="mt-2 mb-1 pt-1 border-top-secondary">
              <b-col
                md="12"
              >
                <h4>Events Requiring Escalation / Actions</h4>
                (Please provide at least three examples of Events that require escalation. Example: Trespassing, Loitering)
              </b-col>
            </b-row>
            <!-- Add New Event -->
            <!-- Event Type Field -->
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Event Type"
                  label-for="vmseventTypesNewDesc"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vmseventTypesNewDesc"
                  >
                    <b-form-input
                      id="vmseventTypesNewDesc"
                      ref="newEventTypeEventTypeDescription"
                      v-model="vmsNewEventTypeDescription"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Actions Required Field -->
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Actions Required"
                  label-for="vmsactionsRequiredNewDesc"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vmsactionsRequiredNewDesc"
                  >
                    <b-form-input
                      id="vmsactionsRequiredNewDesc"
                      ref="newEventTypeActionsRequiredDescription"
                      v-model="vmsNewActionsRequiredDescription"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Add Event Type Buttons -->
            <b-row class="mb-1">
              <b-col
                v-if="$can('update', 'clients')"
                md="2"
              >
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  block
                  type="button"
                  @click="handleAddEventType()"
                >
                  {{ vmsAddEventTypeBtnLable }}
                </b-button>
              </b-col>
              <b-col
                v-if="$can('update', 'clients')"
                md="2"
              >
                <b-button
                  variant="secondary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  block
                  type="button"
                  @click="resetVMSEventTypeForm()"
                >
                  Cancel
                </b-button>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col
                v-if="$can('update', 'clients')"
                md="12"
              >
                <span>Items in the table below will be save when you click on the Save Changes button at the bottom of the form.</span>
              </b-col>
            </b-row>
            <!-- table -->
            <b-table
              ref="eventTypeTable"
              class="position-relative"
              :items="clientSiteData.videomonitoringservices.eventTypes"
              responsive
              :fields="vmsEventTypestableColumns"
              primary-key="itemID"
              show-empty
              empty-text=""
              style="min-height: 10rem;"
            >
              <template empty="">
                No Site found for this client
              </template>

              <!-- Column: itemID -->
              <template #cell(itemID)="data">
                <div class="text-nowrap">
                  {{ data.item.itemID }}
                </div>
              </template>
              <!-- Column: eventTypeDescription -->
              <template #cell(eventTypeDescription)="data">
                <div class="text-nowrap">
                  {{ data.item.eventTypeDescription }}
                </div>
              </template>
              <!-- Column: actionsRequiredDescription -->
              <template #cell(actionsRequiredDescription)="data">
                <div class="text-nowrap">
                  {{ data.item.actionsRequiredDescription }}
                </div>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-dropdown
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >

                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item
                    v-if="$can('update', 'clients')"
                    @click="handleEditEventTypeFillForm(data.item)"
                  >
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Edit</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-if="$can('delete', 'clients')"
                    @click="handleDeleteEventType(data.item)"
                  >
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
            <!-- Pagination -->
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              />
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-right justify-content-right justify-content-sm-end"
              >
                <b-pagination
                  v-model="vmsEventTypesCurrentPage"
                  :total-rows="vmstotalEventTypes"
                  :per-page="vmsperPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
            <!-- ***** Procedure ***** -->
            <b-row class="mt-2 mb-1 pt-1 border-top-secondary">
              <b-col
                md="12"
              >
                <h4>Procedure</h4>
              </b-col>
            </b-row>
            <!-- Technical Procedures Field -->
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Procedure in case of Technical Problems"
                  label-for="vmsTechProcedures"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vmsTechProcedures"
                  >
                    <b-form-input
                      id="vmsTechProcedures"
                      v-model="clientSiteData.videomonitoringservices.technicalProcedures"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Site Down Procedures Field -->
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Procedure in case of Site Down"
                  label-for="vmsSiteDownProcedures"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vmsSiteDownProcedures"
                  >
                    <b-form-input
                      id="vmsSiteDownProcedures"
                      v-model="clientSiteData.videomonitoringservices.siteDownProcedures"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Camera Down Procedures Field -->
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Procedure in case of Camera Down"
                  label-for="vmsCameraDownProcedures"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vmsCameraDownProcedures"
                  >
                    <b-form-input
                      id="vmsCameraDownProcedures"
                      v-model="clientSiteData.videomonitoringservices.cameraDownProcedures"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- ***** Devices Information ***** -->
            <b-row class="mt-2 mb-1 pt-1 border-top-secondary">
              <b-col
                md="12"
              >
                <h4>Devices Information</h4>
                (Please provide details on your NVR / Cloud Porvider. Example HikVision NVR Model Number XXXXXX, Eagle Eye VMS)
              </b-col>
            </b-row>
            <!-- Add New Device Form -->
            <!-- Device Type Field -->
            <b-row>
              <b-col
                md="12"
              >
                <b-form-group
                  label="Device Description"
                  label-for="vmsnewDeviceDescription"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vmsnewDeviceDescription"
                  >
                    <b-form-input
                      id="vmsnewDeviceDescription"
                      v-model="vmsNewDeviceDescription"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- IP/Host -->
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group
                  label="IP / Host"
                  label-for="vmsnewDeviceIPhost"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vmsnewDeviceIPhost"
                  >
                    <b-form-input
                      id="vmsnewDeviceIPhost"
                      v-model="vmsNewDeviceIPhost"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Port -->
            <b-row>
              <b-col
                md="1"
              >
                <b-form-group
                  label="Port"
                  label-for="vmsnewDevicePort"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vmsnewDevicePort"
                  >
                    <b-form-input
                      id="vmsnewDeviceIPhost"
                      v-model="vmsNewDevicePort"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Device AppProtocol -->
            <b-row>
              <b-col
                md="3"
              >
                <b-form-group
                  label="Application Protocol"
                  label-for="vmsnewDeviceAppProtocol"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vmsnewDeviceAppProtocol"
                  >
                    <b-form-input
                      id="vmsnewDeviceAppProtocol"
                      v-model="vmsNewDeviceAppProtocol"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Device Username / Password -->
            <b-row>
              <b-col
                md="3"
              >
                <b-form-group
                  label="Username"
                  label-for="vmsnewDeviceUsername"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vmsnewDeviceUsername"
                  >
                    <b-form-input
                      id="vmsnewDeviceUsername"
                      v-model="vmsNewDeviceUsername"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="3"
              >
                <b-form-group
                  label="Password"
                  label-for="vmsnewDevicePassword"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vmsnewDevicePassword"
                  >
                    <b-form-input
                      id="vmsnewDevicePassword"
                      v-model="vmsNewDevicePassword"
                      type="password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Device Edge IP/host -->
            <b-row class="mt-1">
              <b-col
                md="6"
              >
                <b-form-group
                  label="Admin Portal IP / host"
                  label-for="vmsnewDeviceEdgeIPhost"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vmsnewDeviceEdgeIPhost"
                  >
                    <b-form-input
                      id="vmsnewDeviceEdgeIPhost"
                      v-model="vmsNewDeviceEdgeIPhost"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Device EdgeUsername / EdgePassword -->
            <b-row>
              <b-col
                md="3"
              >
                <b-form-group
                  label="Admin Portal Username"
                  label-for="vmsnewDeviceEdgeUsername"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vmsnewDeviceEdgeUsername"
                  >
                    <b-form-input
                      id="vmsnewDeviceEdgeUsername"
                      v-model="vmsNewDeviceEdgeUsername"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="3"
              >
                <b-form-group
                  label="Admin Portal Password"
                  label-for="vmsnewDeviceEdgePassword"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vmsnewDeviceEdgePassword"
                  >
                    <b-form-input
                      id="vmsnewDeviceEdgePassword"
                      v-model="vmsNewDeviceEdgePassword"
                      type="password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Add Device Buttons -->
            <b-row class="mb-1">
              <b-col
                v-if="$can('update', 'clients')"
                md="2"
              >
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  block
                  type="button"
                  @click="handleAddDevice()"
                >
                  {{ vmsAddDeviceBtnLable }}
                </b-button>
              </b-col>
              <b-col
                v-if="$can('update', 'clients')"
                md="2"
              >
                <b-button
                  variant="secondary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  block
                  type="button"
                  @click="resetVMSDeviceForm()"
                >
                  Cancel
                </b-button>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col
                v-if="$can('update', 'clients')"
                md="12"
              >
                <span>Items in the table below will be save when you click on the Save Changes button at the bottom of the form.</span>
              </b-col>
            </b-row>
            <!-- *** Devices table *** -->
            <b-table
              ref="devicesTable"
              class="position-relative"
              :items="clientSiteData.videomonitoringservices.devices"
              responsive
              :fields="vmsDevicestableColumns"
              primary-key="itemID"
              show-empty
              empty-text=""
              style="min-height: 10rem;"
            >
              <template empty="">
                No Site found for this client
              </template>

              <!-- Column: itemID -->
              <template #cell(itemID)="data">
                <div class="text-nowrap">
                  {{ data.item.itemID }}
                </div>
              </template>
              <!-- Column: deviceDescription -->
              <template #cell(eventTypeDescription)="data">
                <div class="text-nowrap">
                  {{ data.item.deviceDescription }}
                </div>
              </template>
              <!-- Column: deviceIPhost -->
              <template #cell(deviceIPhost)="data">
                <div class="text-nowrap">
                  {{ data.item.iphost }}
                </div>
              </template>
              <!-- Column: port -->
              <template #cell(port)="data">
                <div class="text-nowrap">
                  {{ data.item.port }}
                </div>
              </template>
              <!-- Column: appProtocol -->
              <template #cell(appProtocol)="data">
                <div class="text-nowrap">
                  {{ data.item.appProtocol }}
                </div>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-dropdown
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >

                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item
                    v-if="$can('update', 'clients')"
                    @click="handleEditDeviceFillForm(data.item)"
                  >
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Edit</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-if="$can('delete', 'clients')"
                    @click="handleDeleteDevice(data.item)"
                  >
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
            <!-- Pagination -->
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              />
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-right justify-content-right justify-content-sm-end"
              >
                <b-pagination
                  v-model="vmsEventTypesCurrentPage"
                  :total-rows="vmstotalEventTypes"
                  :per-page="vmsperPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
            <!-- ***** Internet Access ***** -->
            <b-row class="mt-2 mb-1 pt-1 border-top-secondary">
              <b-col
                md="12"
              >
                <h4>Internet Access</h4>
              </b-col>
            </b-row>
            <!-- Internet Access Type and speed -->
            <b-row>
              <!-- Internet Access Type -->
              <b-col
                md="6"
              >
                <b-form-group
                  label="Internet Access Type"
                  label-for="vmsInternetAccessType"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vmsInternetAccessType"
                  >
                    <v-select
                      v-model="clientSiteData.videomonitoringservices.internetAccessType"
                      :options="vmsInternetAccessTypeOptions"
                      :reduce="val => val.value"
                      input-id="vmsInternetAccessType"
                      :clearable="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Upload Speed -->
              <b-col
                md="2"
              >
                <b-form-group
                  label="Upload Speed (Mbps)"
                  label-for="vmsUploadSpeed"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vmsUploadSpeed"
                  >
                    <b-form-input
                      id="vmsUploadSpeed"
                      v-model="clientSiteData.videomonitoringservices.uploadSpeed"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Internet Provider -->
            <b-row>
              <!-- Internet Provider -->
              <b-col
                md="6"
              >
                <b-form-group
                  label="Internet Provider"
                  label-for="vmsInternetProvider"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vmsInternetProvider"
                  >
                    <b-form-input
                      id="vmsInternetProvider"
                      v-model="clientSiteData.videomonitoringservices.internetProvider"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- ***** Camera Details ***** -->
            <b-row class="mt-2 mb-1 border-bottom-secondary">
              <b-col>
                <h4>Camera Details</h4>
              </b-col>
            </b-row>
            <!-- Number of Cameras Monitored Field -->
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Number of Cameras Monitored"
                  label-for="numCamerasMonitored"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="numCamerasMonitored"
                  >
                    <b-form-input
                      id="numCamerasMonitored"
                      v-model="clientSiteData.videomonitoringservices.numCamerasMonitored"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Add New Camera -->
            <!-- New Camera Name Field -->
            <b-row class="mt-2">
              <b-col
                md="6"
              >
                <b-form-group
                  label="Camera Name"
                  label-for="vmsnewCameraName"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vmsnewCameraName"
                  >
                    <b-form-input
                      id="vmsnewCameraName"
                      v-model="vmsNewCameraName"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Include AI Field -->
            <b-row>
              <b-col
                md="6"
              >
                <b-form-checkbox
                  v-model="vmsNewIncludeAIDetection"
                  class="mb-1"
                  name="check-button"
                  switch
                  inline
                >
                  <h5>
                    Include AI Detection
                  </h5>
                  <span>(Typically required for outdoor cameras, can also be used indoors.)</span>
                </b-form-checkbox>
              </b-col>
            </b-row>
            <!-- Add Camera Buttons -->
            <b-row class="mb-1">
              <b-col
                v-if="$can('update', 'clients')"
                md="2"
              >
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  block
                  type="button"
                  @click="handleAddCamera()"
                >
                  {{ vmsAddCameraBtnLable }}
                </b-button>
              </b-col>
              <b-col
                v-if="$can('update', 'clients')"
                md="2"
              >
                <b-button
                  variant="secondary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  block
                  type="button"
                  @click="resetVMSCameraForm()"
                >
                  Cancel
                </b-button>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col
                v-if="$can('update', 'clients')"
                md="12"
              >
                <span>Items in the table below will be save when you click on the Save Changes button at the bottom of the form.</span>
              </b-col>
            </b-row>
            <!-- *** Cameras table *** -->
            <b-table
              ref="camerasTable"
              class="position-relative"
              :items="clientSiteData.videomonitoringservices.cameras"
              responsive
              :fields="vmsCamerasTableColumns"
              primary-key="itemID"
              show-empty
              empty-text=""
              style="min-height: 10rem;"
            >
              <template empty="">
                No Site found for this client
              </template>

              <!-- Column: itemID -->
              <template #cell(itemID)="data">
                <div class="text-nowrap">
                  {{ data.item.itemID }}
                </div>
              </template>
              <!-- Column: cameraName -->
              <template #cell(cameraName)="data">
                <div class="text-nowrap">
                  {{ data.item.cameraName }}
                </div>
              </template>
              <!-- Column: includeAIDetection -->
              <template #cell(includeAIDetection)="data">
                <b-badge
                  pill
                  :variant="`light-${resolveIncludeAIDetectionVariant(data.item.includeAIDetection)}`"
                  class="text-capitalize"
                >
                  {{ data.item.includeAIDetection }}
                </b-badge>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-dropdown
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >

                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item
                    v-if="$can('update', 'clients')"
                    @click="handleEditCameraFillForm(data.item)"
                  >
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Edit</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-if="$can('delete', 'clients')"
                    @click="handleDeleteCamera(data.item)"
                  >
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
            <!-- Pagination -->
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              />
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-right justify-content-right justify-content-sm-end"
              >
                <b-pagination
                  v-model="vmsCamerasCurrentPage"
                  :total-rows="vmstotalCameras"
                  :per-page="vmsperPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </b-tab>
          <!-- ***** ***** Notes ***** ***** -->
          <b-tab>
            <template #title>
              <feather-icon icon="HomeIcon" />
              <span>Notes</span>
            </template>
            <!-- Field: Admin Notes -->
            <b-row>
              <b-col
                md="12"
              >
                <b-form-group
                  label="Admin Notes"
                  label-for="adminnote"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="adminnote"
                  >
                    <b-form-textarea
                      id="adminnote"
                      v-model="clientSiteData.notes_admin"
                      rows="5"
                      max-rows="6"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Client Notes -->
            <b-row>
              <b-col
                md="12"
              >
                <b-form-group
                  label="Client Notes"
                  label-for="clientnote"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="clientnote"
                  >
                    <b-form-textarea
                      id="clientnote"
                      v-model="clientSiteData.notes_client"
                      rows="5"
                      max-rows="6"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Public Notes -->
            <b-row>
              <b-col
                md="12"
              >
                <b-form-group
                  label="Public Notes"
                  label-for="publicnote"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="publicnote"
                  >
                    <b-form-textarea
                      id="publicnote"
                      v-model="clientSiteData.notes_public"
                      rows="5"
                      max-rows="6"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
        <!-- Save Changes Button -->
        <div>
          <b-row class="mt-3">
            <b-col
              v-if="$can('update', 'clients')"
              md="2"
            >
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                block
                type="submit"
              >
                Save Changes
              </b-button>
            </b-col>
          </b-row>
        </div>
        <div>
          <b-row class="mt-3">
            <b-col
              v-if="$can('delete', 'clients')"
              md="2"
            >
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                block
                @click="deleteClientSite()"
              >
                Delete Site
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  ref,
} from '@vue/composition-api'
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { required, email } from '@validations'
import {
  BButton, BMedia, BRow, BCol, BFormGroup, BFormInput, BForm, BFormTextarea, BFormCheckbox, BPagination, BTable, BDropdown, BDropdownItem, BBadge, BTabs, BTab, BInputGroup,
} from 'bootstrap-vue'
// Phone number imports
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
/* eslint-disable global-require */
import {
  LMap, LTileLayer, LMarker, LCircle,
  // LPolygon,
} from 'vue2-leaflet'
import { Icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import vSelect from 'vue-select'
import axios from 'axios'
import ability from '../../../../libs/acl/ability'

// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
/* eslint-disable global-require */

// Notification
// import { useToast } from 'vue-toastification/composition'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BMedia,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    LMap,
    LTileLayer,
    LMarker,
    LCircle,
    BBadge,
    BTabs,
    BTab,
    BInputGroup,
    // LPolygon,
    Cleave,
  },
  props: {
    clientSiteData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      // validation rules
      required,
      email,
      // Map
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 19,
      center: [43.7644292, -79.4850433],
      markerLatLng: [43.7644292, -79.4850433],
      circle: {
        center: [43.7644292, -79.4850433],
        radius: 80,
        color: '#EA5455',
      },
      mapKey: 0,
      // Phone number imports
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
      },

      pageLength: 3,
      dir: false,
      rows: [],
      vmsNewItemID: 0,
      vmsAddEventTypeBtnLable: 'Add Event Type',
      vmsNewEventTypeDescription: null,
      vmsNewActionsRequiredDescription: null,

      vmsNewEventPersonItemID: 0,
      vmsAddEventPersonBtnLable: 'Add Person',
      vmsNewEventPersonName: null,
      vmsNewEventPersonPhone: null,
      vmsNewEventPersonEmail: null,

      vmsNewDeviceItemID: 0,
      vmsAddDeviceBtnLable: 'Add Device',
      vmsNewDeviceDescription: null,
      vmsNewDeviceIPhost: null,
      vmsNewDevicePort: null,
      vmsNewDeviceUsername: null,
      vmsNewDevicePassword: null,
      vmsNewDeviceAppProtocol: null,
      vmsNewDeviceEdgeIPhost: null,
      vmsNewDeviceEdgeUsername: null,
      vmsNewDeviceEdgePassword: null,

      vmsNewCameraItemID: 0,
      vmsNewCameraName: null,
      vmsNewIncludeAIDetection: null,
      vmsAddCameraBtnLable: 'Add Camera',
      // polygon: {
      //   latlngs: [[47.2263299, -1.6222], [47.21024000000001, -1.6270065], [47.1969447, -1.6136169], [47.18527929999999, -1.6143036], [47.1794457, -1.6098404], [47.1775788, -1.5985107], [47.1676598, -1.5753365], [47.1593731, -1.5521622], [47.1593731, -1.5319061], [47.1722111, -1.5143967], [47.1960115, -1.4841843], [47.2095404, -1.4848709], [47.2291277, -1.4683914], [47.2533687, -1.5116501], [47.2577961, -1.5531921], [47.26828069, -1.5621185], [47.2657179, -1.589241], [47.2589612, -1.6204834], [47.237287, -1.6266632], [47.2263299, -1.6222]],
      //   color: '#7367F0',
      // },
    }
  },
  setup() {
    const statusOptions = store.state.appSettings.is_active
    const countryOptions = store.state.appSettings.countries
    const provinceOptions = store.state.appSettings.provinces
    const clienttypeOptions = store.state.appSettings.client_type
    const regionLocOptions = store.state.appSettings.regions_loc
    const vmsaianalyticsOptions = [
      {
        label: 'Yes',
        value: 'Yes',
      },
      {
        label: 'No',
        value: 'No',
      },
      {
        label: 'Not Sure',
        value: 'Not Sure',
      },
    ]
    const vmsEventTypestableColumns = [
      { key: 'eventTypeDescription', label: 'Events Requiring Escalation', sortable: false },
      { key: 'actionsRequiredDescription', label: 'Actions Required', sortable: false },
      { key: 'actions' },
    ]
    const vmsperPage = ref(10)
    const vmstotalEventTypes = ref(0)
    const vmsEventTypesCurrentPage = ref(1)

    const vmsEventPersonstableColumns = [
      { key: 'eventPersonName', label: 'Full Name', sortable: true },
      { key: 'eventPersonPhone', label: 'Phone', sortable: false },
      { key: 'eventPersonEmail', label: 'Email', sortable: false },
      { key: 'actions' },
    ]
    const vmstotalEventPersons = ref(0)
    const vmsEventPersonsCurrentPage = ref(1)

    const vmsDevicestableColumns = [
      { key: 'deviceDescription', label: 'Device Description', sortable: false },
      { key: 'iphost', label: 'IP / Host', sortable: false },
      { key: 'port', label: 'Port', sortable: false },
      { key: 'appProtocol', label: 'Application Protocol', sortable: false },
      { key: 'actions' },
    ]
    const vmstotalDevices = ref(0)
    const vmsDevicesCurrentPage = ref(1)

    const perPageOptions = [10, 25, 50, 100]

    const vmsInternetAccessTypeOptions = [
      {
        label: 'Fibre',
        value: 'Fibre',
      },
      {
        label: 'Cable',
        value: 'Cable',
      },
      {
        label: 'DSL',
        value: 'DSL',
      },
      {
        label: 'Cell',
        value: 'Cell',
      },
    ]

    const vmsCamerasTableColumns = [
      { key: 'cameraName', label: 'Camera Name', sortable: true },
      { key: 'includeAIDetection', label: 'Include AI Detection', sortable: false },
      { key: 'actions' },
    ]
    const vmstotalCameras = ref(0)
    const vmsCamerasCurrentPage = ref(1)

    const resolveIncludeAIDetectionVariant = status => {
      if (status === true) return 'success'
      if (status === false) return 'warning'
      return 'warning'
    }

    const formatPhoneNumber = phone => {
      try {
        if (phone.length === 10) return `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6, 10)}`
        if (phone.length === 11) return `${phone.substring(0, 1)} (${phone.substring(1, 4)}) ${phone.substring(4, 7)}-${phone.substring(7, 11)}`
      } catch (e) { console.log(e) }
      return phone
    }

    return {
      statusOptions,
      countryOptions,
      provinceOptions,
      clienttypeOptions,
      regionLocOptions,
      vmsaianalyticsOptions,

      vmsEventTypestableColumns,
      vmsperPage,
      vmstotalEventTypes,
      vmsEventTypesCurrentPage,

      vmsEventPersonstableColumns,
      vmstotalEventPersons,
      vmsEventPersonsCurrentPage,

      vmsDevicestableColumns,
      vmstotalDevices,
      vmsDevicesCurrentPage,

      perPageOptions,

      vmsInternetAccessTypeOptions,

      vmsCamerasTableColumns,
      vmstotalCameras,
      vmsCamerasCurrentPage,
      resolveIncludeAIDetectionVariant,
      formatPhoneNumber,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    console.log('created')
    this.updateMap()

    if (typeof this.clientSiteData.services === 'undefined') {
      this.clientSiteData.services = {
        guard: false, videomonitoring: false, videoretention: false, guardtour: false, tenant: false, privateinvestigation: false,
      }
    }
    if (typeof this.clientSiteData.videomonitoringservices === 'undefined') {
      const scheduleDays = {
        monday: '',
        tuesday: '',
        wednesday: '',
        thursday: '',
        friday: '',
        saturday: '',
        sunday: '',
      }
      this.clientSiteData.videomonitoringservices = {
        remotevideomonitoring: false,
        mobileappenabled: false,
        voicetalkdown: false,
        videoverification: false,
        virtualguardtours: false,
        elevatormonitoring: false,
        cloudanalytics: false,
        remoteconcierge: false,
        sensoreventtrigger: false,
        initialsiteprogramming: false,
        aianalytics: '',
        person1name: '',
        eventPersons: [],
        vmspolicetelnum: '',
        vmsfiretelnum: '',
        schedule: scheduleDays,
        vmstimezone: '',
        eventTypes: [],
        technicalProcedures: '',
        siteDownProcedures: '',
        cameraDownProcedures: '',
        devices: [],
        internetAccessType: '',
        uploadSpeed: '',
        numCamerasMonitored: '',
        cameras: [],
      }
    }
    this.resetVMSEventTypeForm()
    this.resetVMSEventPersonForm()
    this.resetVMSDeviceForm()
    this.resetVMSCameraForm()

    console.log('Page Created: this.clientSiteData')
    console.log(this.clientSiteData)
  },
  methods: {
    updateMap() {
      if (typeof this.clientSiteData.geofence.lat !== 'undefined' && typeof this.clientSiteData.geofence.lon !== 'undefined' && typeof this.clientSiteData.geofence.distance !== 'undefined') {
        console.log('Map Updated')
        this.center = [this.clientSiteData.geofence.lat, this.clientSiteData.geofence.lon]
        this.markerLatLng = [this.clientSiteData.geofence.lat, this.clientSiteData.geofence.lon]
        this.circle = {
          center: [this.clientSiteData.geofence.lat, this.clientSiteData.geofence.lon],
          // eslint-disable-next-line radix
          radius: parseInt(this.clientSiteData.geofence.distance),
          color: '#EA5455',
        }
      }
    },

    onTabChanged(activeTabIndex) {
      if (typeof this.clientSiteData.geofence.lat !== 'undefined' && this.clientSiteData.geofence.lat !== '' && typeof this.clientSiteData.geofence.lon !== 'undefined' && this.clientSiteData.geofence.lon !== '' && typeof this.clientSiteData.geofence.distance !== 'undefined' && this.clientSiteData.geofence.distance !== '') {
        console.log(this.clientSiteData.geofence.lat)
        if (activeTabIndex === 1) {
          this.$nextTick(() => {
            this.$refs.myMap.mapObject.invalidateSize()
          })
        }
      }
    },

    resetVMSEventTypeForm() {
      console.log('resetVMSEventTypeForm')
      this.vmsAddEventTypeBtnLable = 'Add Event Type'
      this.vmsNewItemID = 0
      this.vmsNewEventTypeDescription = null
      this.vmsNewActionsRequiredDescription = null
    },

    handleAddEventType() {
      console.log('handleAddEventType')
      if (this.vmsNewItemID === 0 && this.vmsNewEventTypeDescription !== null && this.vmsNewEventTypeDescription !== ''
        && this.vmsNewActionsRequiredDescription !== null && this.vmsNewActionsRequiredDescription !== '') {
        const theID = this.clientSiteData.videomonitoringservices.eventTypes.length + 1
        const theItem = {
          itemID: theID,
          eventTypeDescription: this.vmsNewEventTypeDescription,
          actionsRequiredDescription: this.vmsNewActionsRequiredDescription,
        }
        // set(this.clientSiteData.videomonitoringservices.eventTypes, this.clientSiteData.videomonitoringservices.eventTypes.length + 1, theItem)
        this.clientSiteData.videomonitoringservices.eventTypes.push(theItem)
        const table = this.$refs.eventTypeTable
        table.refresh()
        this.resetVMSEventTypeForm()
        console.log(this.clientSiteData.videomonitoringservices.eventTypes)
      } else if (this.vmsNewItemID !== 0 && this.vmsNewEventTypeDescription !== null && this.vmsNewEventTypeDescription !== ''
        && this.vmsNewActionsRequiredDescription !== null && this.vmsNewActionsRequiredDescription !== '') {
        const theItem = {
          itemID: this.vmsNewItemID,
          eventTypeDescription: this.vmsNewEventTypeDescription,
          actionsRequiredDescription: this.vmsNewActionsRequiredDescription,
        }
        this.handleEditEventType(theItem)
      }
    },

    handleEditEventTypeFillForm(selectedItem) {
      console.log('handleEditEventTypeFillForm')
      console.log(selectedItem)
      this.vmsAddEventTypeBtnLable = 'Edit Event Type'
      this.vmsNewItemID = selectedItem.itemID
      this.vmsNewEventTypeDescription = selectedItem.eventTypeDescription
      this.vmsNewActionsRequiredDescription = selectedItem.actionsRequiredDescription
    },

    handleEditEventType(selectedItem) {
      console.log('handleEditEventType')
      const index = this.clientSiteData.videomonitoringservices.eventTypes.findIndex(item => item.itemID === selectedItem.itemID)
      if (index !== -1) {
        this.clientSiteData.videomonitoringservices.eventTypes[index].eventTypeDescription = selectedItem.eventTypeDescription
        this.clientSiteData.videomonitoringservices.eventTypes[index].actionsRequiredDescription = selectedItem.actionsRequiredDescription
      }
      const table = this.$refs.eventTypeTable
      table.refresh()
      this.resetVMSEventTypeForm()
      console.log(this.clientSiteData.videomonitoringservices.eventTypes)
    },

    handleDeleteEventType(selectedItem) {
      console.log('handleDeleteEventType')
      let theItemID = -1
      this.clientSiteData.videomonitoringservices.eventTypes.forEach((item, index) => {
        if (item.itemID === selectedItem.itemID) {
          theItemID = index
          console.log('found for delete')
        }
      })
      if (theItemID !== -1) {
        this.clientSiteData.videomonitoringservices.eventTypes.splice(theItemID, 1)
      }
      const table = this.$refs.eventTypeTable
      table.refresh()
      console.log(this.clientSiteData.videomonitoringservices.eventTypes)
    },

    resetVMSEventPersonForm() {
      console.log('resetVMSEventPersonForm')
      this.vmsAddEventPersonBtnLable = 'Add Person'
      this.vmsNewEventPersonItemID = 0
      this.vmsNewEventPersonName = null
      this.vmsNewEventPersonPhone = null
      this.vmsNewEventPersonEmail = null
    },

    handleAddEventPerson() {
      console.log('handleAddEventPerson')
      if (this.vmsNewEventPersonItemID === 0 && this.vmsNewEventPersonName !== null && this.vmsNewEventPersonName !== ''
        && this.vmsNewEventPersonPhone !== null && this.vmsNewEventPersonPhone !== ''
        && this.vmsNewEventPersonEmail !== null && this.vmsNewEventPersonEmail !== '') {
        const theID = this.clientSiteData.videomonitoringservices.eventPersons.length + 1
        const theItem = {
          itemID: theID,
          eventPersonName: this.vmsNewEventPersonName,
          eventPersonPhone: this.vmsNewEventPersonPhone.replaceAll(' ', ''),
          eventPersonEmail: this.vmsNewEventPersonEmail,
        }
        this.clientSiteData.videomonitoringservices.eventPersons.push(theItem)
        const table = this.$refs.eventPersonTable
        table.refresh()
        this.resetVMSEventPersonForm()
        console.log(this.clientSiteData.videomonitoringservices.eventPersons)
      } else if (this.vmsNewEventPersonItemID !== 0 && this.vmsNewEventPersonName !== null && this.vmsNewEventPersonName !== ''
        && this.vmsNewEventPersonPhone !== null && this.vmsNewEventPersonPhone !== ''
        && this.vmsNewEventPersonEmail !== null && this.vmsNewEventPersonEmail !== '') {
        const theItem = {
          itemID: this.vmsNewEventPersonItemID,
          eventPersonName: this.vmsNewEventPersonName,
          eventPersonPhone: this.vmsNewEventPersonPhone.replaceAll(' ', ''),
          eventPersonEmail: this.vmsNewEventPersonEmail,
        }
        this.handleEditEventPerson(theItem)
      }
    },

    handleEditEventPersonFillForm(selectedItem) {
      console.log('handleEditEventPersonFillForm')
      console.log(selectedItem)
      this.vmsAddEventPersonBtnLable = 'Edit Person'
      this.vmsNewEventPersonItemID = selectedItem.itemID
      this.vmsNewEventPersonName = selectedItem.eventPersonName
      this.vmsNewEventPersonPhone = selectedItem.eventPersonPhone
      this.vmsNewEventPersonEmail = selectedItem.eventPersonEmail
    },

    handleEditEventPerson(selectedItem) {
      console.log('handleEditEventPerson')
      const index = this.clientSiteData.videomonitoringservices.eventPersons.findIndex(item => item.itemID === selectedItem.itemID)
      if (index !== -1) {
        this.clientSiteData.videomonitoringservices.eventPersons[index].eventPersonName = this.vmsNewEventPersonName
        this.clientSiteData.videomonitoringservices.eventPersons[index].eventPersonPhone = this.vmsNewEventPersonPhone.replaceAll(' ', '')
        this.clientSiteData.videomonitoringservices.eventPersons[index].eventPersonEmail = this.vmsNewEventPersonEmail
      }
      const table = this.$refs.eventPersonTable
      table.refresh()
      this.resetVMSEventPersonForm()
      console.log(this.clientSiteData.videomonitoringservices.eventPersons)
    },

    handleDeleteEventPerson(selectedItem) {
      console.log('handleDeleteEventPerson')
      let theItemID = -1
      this.clientSiteData.videomonitoringservices.eventPersons.forEach((item, index) => {
        if (item.itemID === selectedItem.itemID) {
          theItemID = index
          console.log('found for delete')
          console.log(theItemID)
        }
      })
      if (theItemID !== -1) {
        this.clientSiteData.videomonitoringservices.eventPersons.splice(theItemID, 1)
      }
      const table = this.$refs.eventPersonTable
      table.refresh()
      console.log(this.clientSiteData.videomonitoringservices.eventPersons)
    },

    resetVMSDeviceForm() {
      console.log('resetVMSDeviceForm')
      this.vmsAddDeviceBtnLable = 'Add Device'
      this.vmsNewDeviceItemID = 0
      this.vmsNewDeviceDescription = null
      this.vmsNewDeviceIPhost = null
      this.vmsNewDevicePort = null
      this.vmsNewDeviceUsername = null
      this.vmsNewDevicePassword = null
      this.vmsNewDeviceAppProtocol = null
      this.vmsNewDeviceEdgeIPhost = null
      this.vmsNewDeviceEdgeUsername = null
      this.vmsNewDeviceEdgePassword = null
    },

    handleAddDevice() {
      console.log('handleAddDevice')
      if (this.vmsNewDeviceItemID === 0 && this.vmsNewDeviceDescription !== null && this.vmsNewDeviceDescription !== ''
        && this.vmsNewDeviceIPhost !== null && this.vmsNewDeviceIPhost !== ''
        && this.vmsNewDevicePort !== null && this.vmsNewDevicePort !== ''
        && this.vmsNewDeviceUsername !== null && this.vmsNewDeviceUsername !== ''
        && this.vmsNewDevicePassword !== null && this.vmsNewDevicePassword !== ''
        && this.vmsNewDeviceAppProtocol !== null && this.vmsNewDeviceAppProtocol !== ''
        && this.vmsNewDeviceEdgeIPhost !== null && this.vmsNewDeviceEdgeIPhost !== ''
        && this.vmsNewDeviceEdgeUsername !== null && this.vmsNewDeviceEdgeUsername !== ''
        && this.vmsNewDeviceEdgePassword !== null && this.vmsNewDeviceEdgePassword !== ''
      ) {
        const theID = this.clientSiteData.videomonitoringservices.devices.length + 1
        const theItem = {
          itemID: theID,
          deviceDescription: this.vmsNewDeviceDescription,
          iphost: this.vmsNewDeviceIPhost,
          port: this.vmsNewDevicePort,
          username: this.vmsNewDeviceUsername,
          password: this.vmsNewDevicePassword,
          appProtocol: this.vmsNewDeviceAppProtocol,
          edgeIPhost: this.vmsNewDeviceEdgeIPhost,
          edgeUsername: this.vmsNewDeviceEdgeUsername,
          edgePassword: this.vmsNewDeviceEdgePassword,
        }
        this.clientSiteData.videomonitoringservices.devices.push(theItem)
        const table = this.$refs.devicesTable
        table.refresh()
        this.resetVMSDeviceForm()
        console.log(this.clientSiteData.videomonitoringservices.devices)
      } else if (this.vmsNewDeviceItemID !== 0 && this.vmsNewDeviceDescription !== null && this.vmsNewDeviceDescription !== ''
        && this.vmsNewDeviceIPhost !== null && this.vmsNewDeviceIPhost !== ''
        && this.vmsNewDevicePort !== null && this.vmsNewDevicePort !== ''
        && this.vmsNewDeviceUsername !== null && this.vmsNewDeviceUsername !== ''
        && this.vmsNewDevicePassword !== null && this.vmsNewDevicePassword !== ''
        && this.vmsNewDeviceAppProtocol !== null && this.vmsNewDeviceAppProtocol !== ''
        && this.vmsNewDeviceEdgeIPhost !== null && this.vmsNewDeviceEdgeIPhost !== ''
        && this.vmsNewDeviceEdgeUsername !== null && this.vmsNewDeviceEdgeUsername !== ''
        && this.vmsNewDeviceEdgePassword !== null && this.vmsNewDeviceEdgePassword !== ''
      ) {
        const theItem = {
          itemID: this.vmsNewDeviceItemID,
          deviceDescription: this.vmsNewDeviceDescription,
          iphost: this.vmsNewDeviceIPhost,
          port: this.vmsNewDevicePort,
          username: this.vmsNewDeviceUsername,
          password: this.vmsNewDevicePassword,
          appProtocol: this.vmsNewDeviceAppProtocol,
          edgeIPhost: this.vmsNewDeviceEdgeIPhost,
          edgeUsername: this.vmsNewDeviceEdgeUsername,
          edgePassword: this.vmsNewDeviceEdgePassword,
        }
        this.handleEditDevice(theItem)
      }
    },

    handleEditDeviceFillForm(selectedItem) {
      console.log('handleEditDeviceFillForm')
      console.log(selectedItem)
      this.vmsAddDeviceBtnLable = 'Edit Person'
      this.vmsNewDeviceItemID = selectedItem.itemID
      this.vmsNewDeviceDescription = selectedItem.deviceDescription
      this.vmsNewDeviceIPhost = selectedItem.iphost
      this.vmsNewDevicePort = selectedItem.port
      this.vmsNewDeviceUsername = selectedItem.username
      this.vmsNewDevicePassword = selectedItem.password
      this.vmsNewDeviceAppProtocol = selectedItem.appProtocol
      this.vmsNewDeviceEdgeIPhost = selectedItem.edgeIPhost
      this.vmsNewDeviceEdgeUsername = selectedItem.edgeUsername
      this.vmsNewDeviceEdgePassword = selectedItem.edgePassword
    },

    handleEditDevice(selectedItem) {
      console.log('handleEditDevice')
      const index = this.clientSiteData.videomonitoringservices.devices.findIndex(item => item.itemID === selectedItem.itemID)
      if (index !== -1) {
        this.clientSiteData.videomonitoringservices.devices[index].deviceDescription = this.vmsNewDeviceDescription
        this.clientSiteData.videomonitoringservices.devices[index].iphost = this.vmsNewDeviceIPhost
        this.clientSiteData.videomonitoringservices.devices[index].port = this.vmsNewDevicePort
        this.clientSiteData.videomonitoringservices.devices[index].username = this.vmsNewDeviceUsername
        this.clientSiteData.videomonitoringservices.devices[index].password = this.vmsNewDevicePassword
        this.clientSiteData.videomonitoringservices.devices[index].appProtocol = this.vmsNewDeviceAppProtocol
        this.clientSiteData.videomonitoringservices.devices[index].edgeIPhost = this.vmsNewDeviceEdgeIPhost
        this.clientSiteData.videomonitoringservices.devices[index].edgeUsername = this.vmsNewDeviceEdgeUsername
        this.clientSiteData.videomonitoringservices.devices[index].edgePassword = this.vmsNewDeviceEdgePassword
      }
      const table = this.$refs.devicesTable
      table.refresh()
      this.resetVMSEventPersonForm()
      console.log(this.clientSiteData.videomonitoringservices.devices)
    },

    handleDeleteDevice(selectedItem) {
      console.log('handleDeleteDevice')
      let theItemID = -1
      this.clientSiteData.videomonitoringservices.devices.forEach((item, index) => {
        if (item.itemID === selectedItem.itemID) {
          theItemID = index
          console.log('found for delete')
          console.log(theItemID)
        }
      })
      if (theItemID !== -1) {
        this.clientSiteData.videomonitoringservices.devices.splice(theItemID, 1)
      }
      const table = this.$refs.devicesTable
      table.refresh()
      console.log(this.clientSiteData.videomonitoringservices.devices)
    },

    resetVMSCameraForm() {
      console.log('resetVMSCameraForm')
      this.vmsAddCameraBtnLable = 'Add Camera'
      this.vmsNewCameraItemID = 0
      this.vmsNewCameraName = null
      this.vmsNewIncludeAIDetection = false
    },

    handleAddCamera() {
      console.log('handleAddCamera')
      if (this.vmsNewCameraItemID === 0 && this.vmsNewCameraName !== null && this.vmsNewCameraName !== '') {
        const theID = this.clientSiteData.videomonitoringservices.cameras.length + 1
        const theItem = {
          itemID: theID,
          cameraName: this.vmsNewCameraName,
          includeAIDetection: this.vmsNewIncludeAIDetection,
        }
        this.clientSiteData.videomonitoringservices.cameras.push(theItem)
        const table = this.$refs.camerasTable
        table.refresh()
        this.resetVMSCameraForm()
        console.log(this.clientSiteData.videomonitoringservices.cameras)
      } else if (this.vmsNewCameraItemID !== 0 && this.vmsNewCameraName !== null && this.vmsNewCameraName !== '') {
        const theItem = {
          itemID: this.vmsNewCameraItemID,
          cameraName: this.vmsNewCameraName,
          includeAIDetection: this.vmsNewIncludeAIDetection,
        }
        this.handleEditCamera(theItem)
      }
    },

    handleEditCameraFillForm(selectedItem) {
      console.log('handleEditCameraFillForm')
      console.log(selectedItem)
      this.vmsAddCameraBtnLable = 'Edit Camera'
      this.vmsNewCameraItemID = selectedItem.itemID
      this.vmsNewCameraName = selectedItem.cameraName
      this.vmsNewIncludeAIDetection = selectedItem.includeAIDetection
    },

    handleEditCamera(selectedItem) {
      console.log('handleEditCamera')
      const index = this.clientSiteData.videomonitoringservices.cameras.findIndex(item => item.itemID === selectedItem.itemID)
      if (index !== -1) {
        this.clientSiteData.videomonitoringservices.cameras[index].cameraName = selectedItem.cameraName
        this.clientSiteData.videomonitoringservices.cameras[index].includeAIDetection = selectedItem.includeAIDetection
      }
      const table = this.$refs.camerasTable
      table.refresh()
      this.resetVMSCameraForm()
      console.log(this.clientSiteData.videomonitoringservices.cameras)
    },

    handleDeleteCamera(selectedItem) {
      console.log('handleDeleteCamera')
      let theItemID = -1
      this.clientSiteData.videomonitoringservices.cameras.forEach((item, index) => {
        if (item.itemID === selectedItem.itemID) {
          theItemID = index
          console.log('found for delete')
        }
      })
      if (theItemID !== -1) {
        this.clientSiteData.videomonitoringservices.cameras.splice(theItemID, 1)
      }
      const table = this.$refs.camerasTable
      table.refresh()
      console.log(this.clientSiteData.videomonitoringservices.cameras)
    },

    onSubmit(e) {
      e.preventDefault()
      console.log('onSubmit called')
      this.$refs.observer.validate().then(success => {
        if (!success) {
          // Show modal for validation error
          this.$refs.validationErrorModal.show()
          return
        }
        // If validation passed, call validateForm
        this.validateForm()
      })
    },

    validateForm() {
      console.log('validateForm called')
      if (ability.can('update', 'clients')) {
        // Temporary Add: Adding additional fields to data set that will be included in for at later date
        this.clientSiteData.privatelabelname = ''
        // Manual Validations

        this.$refs.observer.validate().then(success => {
          if (success) {
            console.log('form validated')
            console.log(this.clientSiteData)
            const cleanData = JSON.parse(JSON.stringify(this.clientSiteData))
            cleanData.phone = cleanData.phone.replaceAll(' ', '')
            cleanData.videomonitoringservices.vmspolicetelnum = cleanData.videomonitoringservices.vmspolicetelnum.replaceAll(' ', '')
            cleanData.videomonitoringservices.vmsfiretelnum = cleanData.videomonitoringservices.vmsfiretelnum.replaceAll(' ', '')
            store.dispatch('app-client/updateClientSite', cleanData)
              .then(response => {
                console.log(response)
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Client Site Updated',
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
              })
              .catch(error => {
                console.log(error)
                let theErrorStr = 'The following error(s) where received:\n'
                if (typeof error.response.data.errors !== 'undefined') {
                  error.response.data.errors.forEach(item => {
                    theErrorStr += `${item.message}\n`
                  })
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error Updating Client Site information.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: theErrorStr,
                  },
                })
              })
          } else {
            console.log('form not validated')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error Updating Client Site information.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'Please fill in all required fields on all Tabs',
              },
            })
          }
        }).catch(error => {
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error Updating Client Site information.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Please fill in all required fields on all Tabs',
            },
          })
        })
      }
    },

    deleteClientSite() {
      if (this.$can('delete', 'clients')) {
        console.log('delete client site')
        console.log(this.clientSiteData)
        store.dispatch('app-client/deleteClientSite', this.clientSiteData)
          .then(response => {
            console.log(response)
            const nextRout = { name: 'apps-clients-view', params: { clientname: this.clientSitesData.clientname } }
            // Route client
            this.$router.replace(nextRout)
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Client Site Delete',
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
              })
          })
          .catch(error => {
            console.log(error)
            let theErrorStr = 'The following error(s) where received:\n'
            if (typeof error.response.data.errors !== 'undefined') {
              error.response.data.errors.forEach(item => {
                theErrorStr += `${item.message}\n`
              })
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error Deleteing Client Site.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: theErrorStr,
              },
            })
          })
      }
    },

    lookupAddress() {
      if (this.clientSiteData.address !== '' && this.clientSiteData.city !== '' && this.clientSiteData.province !== '' && this.clientSiteData.country !== '') {
        axios
          .get(`https://nominatim.openstreetmap.org/search?q=${this.clientSiteData.address},${this.clientSiteData.city},${this.clientSiteData.province},${this.clientSiteData.country}&format=json&polygon=1&addressdetails=0`)
          .then(response => {
            const { data } = response // [{}, {}]
            console.log(response)
            if (data.length > 0) {
              console.log('Found address')
              this.clientSiteData.geofence.lat = data[0].lat
              this.clientSiteData.geofence.lon = data[0].lon
              this.clientSiteData.geofence.distance = 80
              setTimeout(() => {
                this.updateMap()
                console.log('force update')
              }, 500)
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Unable to Locate Address',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
.vue2leaflet-map{
  &.leaflet-container{
    height: 500px;
  }
}
</style>
